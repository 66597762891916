@font-face {
  font-family: Cascadia Tablature;
  src: url("CascadiaTablature.1b57b5f0.woff2");
}

@font-face {
  font-family: Cascadia Mono;
  src: url("CascadiaMono.946196d0.woff2");
}

@font-face {
  font-family: Cascadia Mono Italic;
  src: url("CascadiaMonoItalic.f1f6fb9b.woff2");
}

html {
  scroll-behavior: smooth;
}

:root {
  --shadow: #0003;
  --border: #28a0a266;
  --title-width: 260px;
  --controls-max-width: 900px;
  --app-height: 100vh;
  --tablature-font-size: 13px;
  --tablature-sup-font-size: 10px;
  --tablature-sup-top: -.3em;
  --tablature-sub-font-size: 13px;
  --tablature-sub-top: .2em;
  --search-result-padding-vertical: 10px;
  --search-result-padding-horizontal: 30px;
  --tab-bg: #fef8ec;
  --header-bg: #f9f1e1;
  --button-bg: #f5eddd;
  --button-hover-bg: #d1dfd0;
  --button-active-bg: #a9b5a9;
  --fg: #28a0a2;
  --fg-alt: #1c9ff0;
  --fg-alt2: #5ed0fa;
  --fg-disabled: #9ec5c5;
  --accent: #ff6a9e;
  --accent-alt: #f5970a;
}

@media (prefers-color-scheme: dark) {
  :root {
    --shadow: #00000080;
    --border: #0000004d;
    --tab-bg: #262335;
    --header-bg: #241b2f;
    --button-bg: var(--header-bg);
    --button-hover-bg: #342847;
    --button-active-bg: #4f3d6a;
    --fg: #acb3b3;
    --fg-alt: #ffcb6b;
    --fg-alt2: #91f5f8;
    --fg-disabled: #585858;
    --accent: #f78c6c;
    --accent: #f07178;
    --accent-alt: #f78c6c;
  }
}

.tablature .tabTitle {
  color: var(--accent-alt);
  font-family: Cascadia Mono Italic;
}

.tablature .tabTimeline {
  color: var(--fg);
}

.tablature .tabFret {
  color: var(--accent);
}

.tablature .tabSpecial {
  color: var(--fg-alt2);
}

.tablature .tabText {
  color: var(--fg);
}

.tablature .tabChord {
  color: var(--fg-alt);
}

.tablature .tabHeader {
  color: var(--accent-alt);
}

.tablature .tabPrefix {
  color: var(--fg-alt);
}

.tablature .tabSuffix {
  color: var(--accent-alt);
  font-family: Cascadia Mono Italic;
}

.smallViewport {
  display: none;
}

@media only screen and (max-width: 1060px) {
  .smallViewport {
    display: inline;
  }

  .largeViewport {
    display: none;
  }

  :root {
    --tablature-font-size: 10px;
    --tablature-sup-font-size: 8px;
    --tablature-sub-font-size: 10px;
    --search-result-padding-vertical: 5px;
    --search-result-padding-horizontal: 10px;
  }
}

* {
  box-sizing: border-box;
  scrollbar-color: var(--button-hover-bg) var(--tab-bg);
  outline: none;
}

::-webkit-scrollbar {
  background-color: var(--button-hover-bg);
  cursor: pointer;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-corner {
  background-color: var(--tab-bg);
}

::-webkit-scrollbar-track-piece {
  background-color: var(--tab-bg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--button-hover-bg);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--button-active-bg);
}

::-webkit-scrollbar-thumb:active {
  background-color: var(--button-active-bg);
}

body {
  background-color: var(--tab-bg);
  border: 0;
  margin: 0;
  padding: 0;
}

.app {
  height: 100%;
  width: 100%;
  border: 0;
  grid-template-rows: 40px auto;
  margin: 0;
  padding: 0;
  font-family: Cascadia Mono;
  display: grid;
  position: fixed;
}

.header {
  z-index: 100;
  box-shadow: 0 0 6px 0 var(--shadow);
  background-color: var(--header-bg);
  border: 0;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  display: flex;
}

.title {
  width: var(--title-width);
  margin: auto 0;
  padding: 0 10px;
}

.title a {
  text-decoration: none;
}

.title h1 {
  color: var(--fg);
  margin: auto;
  font-family: Cascadia Mono;
  font-size: 24px;
}

.controls {
  max-width: var(--controls-max-width);
  border: 0;
  margin: 0;
  display: flex;
}

.controls * {
  background-color: var(--button-bg);
  color: var(--fg);
  font-family: Cascadia Tablature;
  font-size: 13px;
}

button.header-button, .header-button button {
  box-shadow: 0 0 3px 0 var(--shadow);
  height: 100%;
  cursor: pointer;
  border: 0;
  padding: 10px 20px;
}

.controls button:hover, .controls button:focus {
  background-color: var(--button-hover-bg);
}

.controls button:active {
  background-color: var(--button-active-bg);
}

.controls .selected {
  background-color: var(--button-hover-bg);
}

.controls button.disabled {
  color: var(--fg-disabled);
}

.controls button > * {
  background-color: inherit;
  color: inherit;
}

button.header-button:focus, .header-button button:focus, button.header-button.selected, .header-button button.selected {
  border-top: 1px solid var(--border);
}

button.dropdown-item, .dropdown-item button {
  height: 100%;
  border: 0;
  border-right: 1px solid var(--border);
  cursor: pointer;
  min-width: 50px;
  text-align: center;
  padding: 10px;
}

.dropdown-item {
  border-bottom: 1px solid var(--border);
  flex-direction: row;
  display: flex;
}

.dropdown-item > div {
  white-space: nowrap;
  padding: 10px;
  font-style: italic;
  overflow: hidden;
}

.dropdown-menu {
  box-shadow: 1px 1px 4px 0 var(--shadow);
  border-top: 1px solid var(--border);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  z-index: 1;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.loadingIcon {
  width: 50px;
  padding: 18px 0;
}

.tablatureSearch * {
  font-family: Cascadia Tablature;
  font-size: var(--tablature-font-size);
  color: var(--fg);
}

.tablatureSearch {
  background-color: var(--tab-bg);
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow: auto;
}

.searchBar {
  background-color: var(--button-hover-bg);
  border: 1px solid;
  border-color: var(--shadow);
  width: 300px;
  margin: 5px;
  padding: 10px 30px;
}

.searchBar:focus {
  border-color: var(--fg);
}

.searchResults table, .searchResults th, .searchResults tr, .searchResults td {
  border-collapse: collapse;
  border: 0;
}

.searchResults tr:hover {
  background-color: var(--button-hover-bg);
  cursor: pointer;
}

.searchResults tr:focus {
  background-color: var(--button-hover-bg);
}

.searchResults tr:active {
  background-color: var(--button-active-bg);
}

.searchResults .selected {
  background-color: var(--button-hover-bg);
}

.searchResults td, .searchResults th {
  padding: var(--search-result-padding-vertical) var(--search-result-padding-horizontal);
}

.tablatureViewer {
  background-color: var(--tab-bg);
  display: flex;
  overflow: auto;
}

.tablatureViewer pre {
  border: 0;
  margin: auto;
  padding: 10px;
}

.tablature pre {
  font-family: Cascadia Tablature;
  font-size: var(--tablature-font-size);
  color: var(--fg);
}

.tablature sup, .tablature sub {
  vertical-align: baseline;
  position: relative;
}

.tablature sup {
  font-size: var(--tablature-sup-font-size);
  top: var(--tablature-sup-top);
}

.tablature sup .fontsize-compensation {
  font-size: calc(var(--tablature-font-size)  - var(--tablature-sup-font-size));
}

.tablature sub {
  font-size: var(--tablature-sub-font-size);
  top: var(--tablature-sub-top);
}

.tablature sub .fontsize-compensation {
  font-size: calc(var(--tablature-font-size)  - var(--tablature-sub-font-size));
}

.tablatureEditor {
  resize: none;
  white-space: pre;
  background-color: var(--tab-bg);
  color: var(--fg);
  font-family: Cascadia Tablature;
  font-size: var(--tablature-font-size);
  border: 0;
  outline: none;
  margin: 0;
  padding: 10px 0 10px 10px;
}

.tablaturePrinter {
  display: none;
}

@media print {
  :root {
    --tab-bg: #fff;
    --header-bg: #fff;
    --button-bg: var(--header-bg);
    --button-hover-bg: #ddd;
    --button-active-bg: #eee;
    --fg: #888;
    --fg-alt: #666;
    --fg-alt2: #666;
    --fg-disabled: #aaa;
    --accent: #000;
    --accent-alt: #000;
  }

  .app {
    display: none;
  }

  .tablaturePrinter {
    display: block;
  }
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.b43a7e55.css.map */
