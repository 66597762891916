@font-face {
  font-family: Cascadia Tablature;
  src: url("CascadiaTablature.woff2");
}

@font-face {
  font-family: Cascadia Mono;
  src: url("CascadiaMono.woff2");
}

@font-face {
  font-family: Cascadia Mono Italic;
  src: url("CascadiaMonoItalic.woff2");
}

html {
  scroll-behavior: smooth;
}

:root {
  --shadow: rgba(0, 0, 0, 0.2);
  /* --border: rgba(0, 0, 0, 0.1); */
  --border: #28a0a266;

  --title-width: 260px;
  --controls-max-width: 900px;
  --app-height: 100vh;

  --tablature-font-size: 13px;
  --tablature-sup-font-size: 10px;
  --tablature-sup-top: -0.3em;
  --tablature-sub-font-size: 13px;
  --tablature-sub-top: 0.2em;

  --search-result-padding-vertical: 10px;
  --search-result-padding-horizontal: 30px;

  --tab-bg: #FEF8EC;
  --header-bg: #F9F1E1;
  --button-bg: #f5eddd;
  --button-hover-bg: #D1DFD0;
  --button-active-bg: #A9B5A9;

  --fg: #28a0a2;
  --fg-alt: #1c9ff0;
  --fg-alt2: #5ed0fa;
  --fg-disabled: #9ec5c5;

  --accent: #ff6a9e;
  --accent-alt: #f5970a;
}

@media (prefers-color-scheme: dark) {
  :root {
    --shadow: rgba(0, 0, 0, 0.5);
    --border: rgba(0, 0, 0, 0.3);
    --tab-bg: #262335;
    --header-bg: #241B2F;
    --button-bg: var(--header-bg);
    --button-hover-bg: #342847;
    --button-active-bg: #4f3d6a;

    --fg: #acb3b3;
    --fg-alt: #ffcb6b;
    --fg-alt2: #91f5f8;
    /* --fg-alt2: #94afeb; */
    --fg-disabled: #585858;

    --accent: #f78c6c;
    --accent: #f07178;
    /* --accent-alt: #94afeb; */
    /* --accent-alt: #c792ea; */
    --accent-alt: #f78c6c;
  }
}

.tablature .tabTitle {
  font-family: Cascadia Mono Italic;
  color: var(--accent-alt);
}

.tablature .tabTimeline {
  color: var(--fg);
}

.tablature .tabFret {
  color: var(--accent);
}

.tablature .tabSpecial {
  color: var(--fg-alt2);
}

.tablature .tabText {
  color: var(--fg);
}

.tablature .tabChord {
  color: var(--fg-alt);
}

.tablature .tabHeader {
  color: var(--accent-alt);
}

.tablature .tabPrefix {
  color: var(--fg-alt);
}

.tablature .tabSuffix {
  font-family: Cascadia Mono Italic;
  color: var(--accent-alt);
}

.smallViewport {
  display: none;
}

/* max-width must be larger that sum of --title-width and --controls-max-width */
@media only screen and (max-width: 1060px) {
  .smallViewport {
    display: inline;
  }

  .largeViewport {
    display: none;
  }

  :root {
    --tablature-font-size: 10px;
    --tablature-sup-font-size: 8px;
    --tablature-sub-font-size: 10px;
    --search-result-padding-vertical: 5px;
    --search-result-padding-horizontal: 10px;
  }
}

* {
  box-sizing: border-box;
  outline: none;

  scrollbar-color: var(--button-hover-bg) var(--tab-bg);
}

/* Minimalistic Webkit scrollbars for browsers not supporting scollbar-color (Chrome, Safari) */
::-webkit-scrollbar {
  background-color: var(--button-hover-bg);
  cursor: pointer;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-corner {
  background-color: var(--tab-bg);
}

::-webkit-scrollbar-track-piece {
  background-color: var(--tab-bg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--button-hover-bg);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--button-active-bg);
}

::-webkit-scrollbar-thumb:active {
  background-color: var(--button-active-bg);
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: var(--tab-bg);
}

.app {
  display: grid;
  grid-template-rows: 40px auto;
  border: 0;
  margin: 0;
  padding: 0;
  /* height: var(--app-height); */
  position: fixed;
  height: 100%;
  width: 100%;

  font-family: Cascadia Mono;
}

.header {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  border: 0;
  z-index: 100;

  justify-content: center;
  box-shadow: 0 0 6px 0 var(--shadow);
  background-color: var(--header-bg);
}

.title {
  margin: auto 0;
  padding: 0 10px;
  width: var(--title-width);
}

.title a {
  text-decoration: none;
}

.title h1 {
  margin: auto auto;

  color: var(--fg);
  font-family: Cascadia Mono;
  font-size: 24px;
}

/**************/
/** CONTROLS **/
/**************/

.controls {
  display: flex;
  margin: 0;
  border: 0;
  max-width: var(--controls-max-width);
}

.controls * {
  background-color: var(--button-bg);
  font-family: Cascadia Tablature;
  font-size: 13px;
  color: var(--fg);
}

button.header-button,
.header-button button {
  box-shadow: 0 0 3px 0 var(--shadow);
  height: 100%;
  border: 0;
  cursor: pointer;
  padding: 10px 20px;
}

.controls button:hover {
  background-color: var(--button-hover-bg);
}

.controls button:focus {
  background-color: var(--button-hover-bg);
}

.controls button:active {
  background-color: var(--button-active-bg);
}

.controls .selected {
  background-color: var(--button-hover-bg);
}

.controls button.disabled {
  color: var(--fg-disabled);
}

.controls button>* {
  background-color: inherit;
  color: inherit;
}

button.header-button:focus,
.header-button button:focus,
button.header-button.selected,
.header-button button.selected
{
  border-top: 1px solid var(--border);
}

button.dropdown-item,
.dropdown-item button {
  height: 100%;
  border: 0;
  border-right: 1px solid var(--border);
  cursor: pointer;
  padding: 10px 10px;
  min-width: 50px;
  text-align: center;
}

.dropdown-item {
  border-bottom: 1px solid var(--border);
  flex-direction: row;
  display: flex;
}

.dropdown-item>div {
  padding: 10px 10px;
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  box-shadow: 1px 1px 4px 0 var(--shadow);
  border-top: 1px solid var(--border);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  z-index: 1;
  flex-direction: column;
  display: flex;
}

.loadingIcon {
  padding: 18px 0px;
  width: 50px;
}

/************/
/** SEARCH **/
/************/

.tablatureSearch * {
  font-family: Cascadia Tablature;
  font-size: var(--tablature-font-size);
  color: var(--fg);
}

.tablatureSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--tab-bg);
  overflow: auto;
}

.searchBar {
  margin: 5px;
  padding: 10px 30px;
  background-color: var(--button-hover-bg);
  border: 1px solid;
  border-color: var(--shadow);
  width: 300px;
}

.searchBar:focus {
  border-color: var(--fg);
}

.searchResults table,
.searchResults th,
.searchResults tr,
.searchResults td {
  border: 0;
  border-collapse: collapse;
}

.searchResults tr:hover {
  background-color: var(--button-hover-bg);
  cursor: pointer;
}

.searchResults tr:focus {
  background-color: var(--button-hover-bg);
}

.searchResults tr:active {
  background-color: var(--button-active-bg);
}

.searchResults .selected {
  background-color: var(--button-hover-bg);
}

.searchResults td,
.searchResults th {
  padding: var(--search-result-padding-vertical) var(--search-result-padding-horizontal);
}

/***************/
/** TABLATURE **/
/***************/

.tablatureViewer {
  display: flex;
  overflow: auto;
  background-color: var(--tab-bg);
}

.tablatureViewer pre {
  margin: auto;
  border: 0;
  padding: 10px;
}

.tablature pre {
  font-family: Cascadia Tablature;
  font-size: var(--tablature-font-size);
  color: var(--fg);
}

.tablature sup,
.tablature sub {
  vertical-align: baseline;
  position: relative;
}

.tablature sup {
  font-size: var(--tablature-sup-font-size);
  top: var(--tablature-sup-top);
}

.tablature sup .fontsize-compensation {
  font-size: calc(var(--tablature-font-size) - var(--tablature-sup-font-size));
}

.tablature sub {
  font-size: var(--tablature-sub-font-size);
  top: var(--tablature-sub-top);
}

.tablature sub .fontsize-compensation {
  font-size: calc(var(--tablature-font-size) - var(--tablature-sub-font-size));
}

.tablatureEditor {
  margin: 0;
  border: 0;
  padding: 10px 0 10px 10px;

  outline: none;
  resize: none;
  white-space: pre;

  background-color: var(--tab-bg);
  color: var(--fg);
  font-family: Cascadia Tablature;
  font-size: var(--tablature-font-size);
}

.tablaturePrinter {
  display: none;
}

@media print {
  :root {
    --tab-bg: #fff;
    --header-bg: #fff;
    --button-bg: var(--header-bg);
    --button-hover-bg: #ddd;
    --button-active-bg: #eee;

    --fg: #888;
    --fg-alt: #666;
    --fg-alt2: #666;
    --fg-disabled: #aaa;

    --accent: #000;
    --accent-alt: #000;
  }

  .app {
    display: none;
  }

  .tablaturePrinter {
    display: block;
  }
}

.hidden {
  display: none
}
